import React from 'react';

import { useRouter } from 'next/router';

import bohumSVG from './assets/bohum.svg';
import carrefourSVG from './assets/carrefour.svg';
import cdprojektSVG from './assets/cdprojekt.svg';
import ikeaSVG from './assets/ikea.svg';
import nottinghamSVG from './assets/nottingham.svg';
import redbullSVG from './assets/redbull.svg';
import revolutSVG from './assets/revolut.svg';
import stripeSVG from './assets/stripe.svg';
import survicateSVG from './assets/survicate.svg';
import zendeskSVG from './assets/zendesk.svg';

interface BrandsWhiteProps {
    lazy?: boolean;
    fetchpriority?: boolean;
}

export default function BrandsWhite({ lazy = true, fetchpriority = false }: BrandsWhiteProps): JSX.Element {
    const { locale } = useRouter();
    return (
        <ul>
            <li>
                <figure>
                    <img
                        width="75"
                        height="45"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={redbullSVG.src}
                        alt="RedBull"
                    />
                </figure>
            </li>
            <li>
                <figure>
                    <img
                        width="78"
                        height="32"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={ikeaSVG.src}
                        alt="IKEA"
                    />
                </figure>
            </li>
            {locale === 'de' && (
                <li>
                    <figure>
                        <img
                            width="48"
                            height="50"
                            loading={lazy ? 'lazy' : undefined}
                            fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                            src={bohumSVG.src}
                            alt="BOHUM"
                        />
                    </figure>
                </li>
            )}
            <li>
                <figure>
                    <img
                        width="140"
                        height="26"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={survicateSVG.src}
                        alt="Survicate"
                    />
                </figure>
            </li>
            <li>
                <figure>
                    <img
                        width="92"
                        height="50"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={cdprojektSVG.src}
                        alt="CD PROJEKT"
                    />
                </figure>
            </li>
            <li>
                <figure>
                    <img
                        width="49"
                        height="35"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={zendeskSVG.src}
                        alt="Zendesk"
                    />
                </figure>
            </li>
            <li>
                <figure>
                    <img
                        width="88"
                        height="20"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={revolutSVG.src}
                        alt="Revolut"
                    />
                </figure>
            </li>
            <li>
                <figure>
                    <img
                        width="95"
                        height="35"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={nottinghamSVG.src}
                        alt="University of Nottingham"
                    />
                </figure>
            </li>
            {locale !== 'de' && (
                <li>
                    <figure>
                        <img
                            width="70"
                            height="29"
                            loading={lazy ? 'lazy' : undefined}
                            fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                            src={stripeSVG.src}
                            alt="Stripe"
                        />
                    </figure>
                </li>
            )}
            <li>
                <figure>
                    <img
                        width="47"
                        height="35"
                        loading={lazy ? 'lazy' : undefined}
                        fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                        src={carrefourSVG.src}
                        alt="Carrefour"
                    />
                </figure>
            </li>
        </ul>
    );
}
